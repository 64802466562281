<template>
  <div class="card form-card checkout collapse show">
    <div class="card-header d-flex justify-content-between align-items-center">
      <h5>{{ $t('forms.senderName.phoneNumberOrder') }}</h5>
      <button class="btn btn-sm btn-light" @click="backToSenderNameForm" v-if="showEditSenderNameButton">
        <i class="feather icon-edit-2 mx-1"></i>{{$t('edit')}}
      </button>
    </div>
    <div class="sender_name collapse show">
      <div class="card-block task-details ">
        <DynamicForm
          @submit="submitSenderName"
          :schema="senderNameFormSchema"
          :button-data="submitButton"
          :initial-field-value="getInitialValue(formData)"
          @changeHandler="changeHandler"
          submitAlwaysEnabled
          form-class="justify-content-start align-items-start"
        ></DynamicForm>
      </div>
    </div>

    <div class="card-header">
      <h5>{{ $t('forms.payment_title') }}</h5>
    </div>
    <div class="payment collapse">
      <div class="card-block task-details">
        <div class="d-flex justify-content-center">
          <div class="paymentDetail-info">
              <template v-if="phoneNumberLocalizedFee">
                <div class="paymentDetail-param paymentDetail-param_plan text-center">Dedicated phone number</div>
                <div class="paymentDetail-param paymentDetail-monthly_plan text-center">{{ fullSenderNameStr }}</div>
                <div class="paymentDetail-param paymentDetail-param text-center" v-if="formData.phoneNumberAliasZipCode">{{$t('forms.checkout.zipCode')}}: {{ formData.phoneNumberAliasZipCode }}</div>
                <hr>
                <div class="mt-1">
                  <div class="d-flex justify-content-between mb-2" v-if="phoneNumberSetupLocalizedFee">
                    <span class="paymentDetail-param paymentDetail-param_main">{{$t('forms.senderName.phoneSetupFee')}}:</span> <span class="paymentDetail-value paymentDetail-value_main">{{phoneNumberSetupLocalizedFee}}</span>
                  </div>
                  <div class="d-flex justify-content-between" v-if="phoneNumberLocalizedFee && formData.phoneNumberAlias === true">
                    <span class="paymentDetail-param paymentDetail-param_main">Phone number fee</span> <span class="paymentDetail-value paymentDetail-value_main">{{phoneNumberLocalizedFee}} / mo.</span>
                  </div>
                  <div class="d-flex justify-content-between" v-if="smsAndCallPriceLocalizedFee && formData.phoneNumberAlias === true && formData.smsAndCall === true">
                    <span class="paymentDetail-param paymentDetail-param_main">SMS and Call fee</span> <span class="paymentDetail-value paymentDetail-value_main">{{smsAndCallPriceLocalizedFee}} / mo.</span>
                  </div>
                  <hr>
                  <div class="d-flex justify-content-between">
                    <alert type="info" text="forms.billing.currentPaymentMethod" class="mb-1" />
                  </div>
                  <div class="d-flex justify-content-between" v-if="paymentMethod">
                    <span class="paymentDetail-param"> {{ $t('paymentMethodTab.paymentType') }}:</span> <span class="paymentDetail-value">{{ $t(`paymentMethod.${paymentMethod}`) }}</span>
                  </div>
                  <div class="d-flex justify-content-between" v-if="lastFourDigits">
                    <span class="paymentDetail-param"> {{ $t('paymentMethodTab.cardNumber') }}:</span> <span class="paymentDetail-value">*-{{lastFourDigits}}</span>
                  </div>
                  <div class="d-flex justify-content-between" v-if="expiryDate">
                    <span class="paymentDetail-param"> {{ $t('paymentMethodTab.expiry') }}:</span> <span class="paymentDetail-value">{{expiryDate}}</span>
                  </div>
                </div>
              </template>
          </div>
        </div>
        <div class="d-flex justify-content-center py-1">
          <button
            class="btn btn-primary btn-lg shadow-2"
            :disabled="isDisabled"
            @click.prevent="letsPayHandler"
            style="position: relative"
          >
            <spinner-component v-if="isDisabled"></spinner-component>
            {{ $t('forms.buyPackages.buy') }}
          </button>
        </div>
        <div class="paymentDetail-info">
          <hr>
          <div class="pt-2 d-flex justify-content-between">
            <alert type="warning" text="forms.senderName.activationTerms" class="mb-1" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <spinner-component v-if="isLoading" item-class="table-spinner-layout"></spinner-component>
</template>

<script>

import DynamicForm from '@/application/components/forms/DynamicForm'
import { defineAsyncComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useModal } from '@/application/composables/modal'
import { usePhoneNumberAliasFormCreate } from '@/application/composables/formPhoneNumberAlias'

export default {
  name: 'CheckoutPage',
  components: {
    DynamicForm,
    'spinner-component': defineAsyncComponent(() => import('@/application/components/layout/SpinnerComponent.vue')),
    alert: defineAsyncComponent(() => import('@/application/components/elements/Alert.vue')),
  },
  props: {
    sender_name: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter()
    const i18n = useI18n()
    const {
      senderNameFormSchema,
      submitButton,
      changeHandler,
      updatePriceData,
      finishPhoneNumberAliasCheckout,
      storedDataToFormData,
      getInitialValue,
      formDataToStoreData,
      formData,
    } = usePhoneNumberAliasFormCreate(props.sender_name.value.id)
    const isLoading = ref(false)
    const isDisabled = ref(false)
    const paddleIsLoading = ref(false)
    const paymentDone = ref(false)
    const showEditSenderNameButton = ref(false)
    const showEditContactDataButton = ref(false)
    const phoneNumberLocalizedFee = ref('')
    const phoneNumberSetupLocalizedFee = ref('')
    const smsAndCallPriceLocalizedFee = ref('')
    const paymentMethod = ref('')
    const lastFourDigits = ref('')
    const expiryDate = ref('')
    const senderName = ref('')
    const fullSenderNameStr = ref('')
    const sender_name_id = ref('')

    onMounted(async() => {
      phoneNumberLocalizedFee.value = props.sender_name.value.phone_number_localized_fee
      phoneNumberSetupLocalizedFee.value = props.sender_name.value.phone_number_setup_localized_fee
      smsAndCallPriceLocalizedFee.value = props.sender_name.value.sms_and_call_price_localized_fee
      paymentMethod.value = props.sender_name.value.payment_method
      lastFourDigits.value = props.sender_name.value.last_four_digits
      expiryDate.value = props.sender_name.value.expiry_date
      fullSenderNameStr.value = props.sender_name.value.sender_name
      sender_name_id.value = props.sender_name.value.id

      formData.value = storedDataToFormData(props.sender_name.value)
      updatePriceData(props.sender_name.value)
    })

    const backToSenderNameForm = () => {
      showEditSenderNameButton.value = false
      window.$('.sender_name').collapse('show')
      window.$('.payment').collapse('hide')
    }

    const letsPayHandler = async() => {
      isDisabled.value = true
      const { openModal } = useModal()
      const { response } = await finishPhoneNumberAliasCheckout(formDataToStoreData(formData))
      isDisabled.value = false
      isLoading.value = false
      console.log(response)
      openModal(i18n.t('success'), i18n.t('forms.buyPackages.successMessage'), () => {
        router.push('/sender-name/show')
      })
    }

    return {
      isDisabled,
      senderNameFormSchema,
      submitButton,
      changeHandler,
      formData,
      paddleIsLoading,
      showEditSenderNameButton,
      showEditContactDataButton,
      senderName,
      phoneNumberLocalizedFee,
      phoneNumberSetupLocalizedFee,
      smsAndCallPriceLocalizedFee,
      paymentMethod,
      lastFourDigits,
      expiryDate,
      fullSenderNameStr,
      backToSenderNameForm,
      getInitialValue,
      backToContactDataForm: () => {
        if (!paymentDone.value) {
          showEditContactDataButton.value = false
          showEditSenderNameButton.value = true
          window.$('.sender_name').collapse('hide')
          window.$('.payment').collapse('show')
        }
      },
      submitSenderName: () => {
        showEditSenderNameButton.value = true
        window.$('.sender_name').collapse('hide')
        window.$('.payment').collapse('show')
      },
      letsPayHandler,
      isLoading,
    }
  },
}
</script>
