import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { createSenderAlias } from '@/application/services/sender-name'
import { getLocalizedServerError } from '@/application/utils/localization'
import { useModal } from '@/application/composables/modal'

export const usePhoneNumberAliasFormCreate = (senderId) => {
  const i18n = useI18n()
  const { openModal } = useModal()
  const formData = ref({})
  const sender_name_id = ref('')
  const senderNameError = ref(false)
  const phoneNumberLocalizedFeeString = ref('')
  const phoneNumberSetupLocalizedFeeString = ref('')
  const smsAndCallFeeString = ref('')
  const senderNameFormSchema = computed(() => ({
    phoneNumberAlias: {
      htmlLabel: `${i18n.t('forms.checkout.phoneNumberAlias')} <b>+${phoneNumberLocalizedFeeString.value} ${phoneNumberSetupLocalizedFeeString.value ? 'and once-off setup fee' : ''} </b>`,
      name: 'phoneNumberAlias',
      type: 'checkbox',
      as: 'input',
      value: true,
      disabled: true,
      validateOnMount: false,
      rules: '',
      cols: 'col-12',
      labelClass: 'cr checkbox-disabled',
      fieldClass: 'checkbox checkbox-primary',
      description: i18n.t('forms.checkout.phoneNumberAliasDescription'),
    },
    smsAndCall: {
      htmlLabel: `${i18n.t('forms.checkout.smsAndCall')} <b>+${smsAndCallFeeString.value}</b>`,
      name: 'smsAndCall',
      type: 'checkbox',
      as: 'input',
      validateOnMount: false,
      value: true,
      rules: '',
      cols: 'col-12 d-inline',
      labelClass: 'cr',
      fieldClass: 'checkbox checkbox-primary',
      description: i18n.t('forms.checkout.smsAndCallDescription'),
      isHTMLDescription: true,
    },
    phoneNumberAliasZipCode: {
      label: i18n.t('forms.checkout.phoneNumberAliasZipCode'),
      name: 'phoneNumberAliasZipCode',
      as: 'input',
      type: 'text',
      rules: 'max:10|min:5',
      cols: 'col-12',
      description: i18n.t('forms.checkout.phoneNumberAliasZipCodeDescription'),
      value: '',
      inputClass: 'form-control',
    },
  }))

  const submitButton = computed(() => ({
    wrapper: 'justify-content-start',
    class: 'btn btn-primary shadow-2 mt-4',
    text: i18n.t('continue'),
  }))

  const changeHandler = (name, values, errors) => {
    senderNameError.value = false
    formData.value = {
      ...formData.value,
      ...values,
    }
  }

  const updatePriceData = (senderName) => {
    phoneNumberLocalizedFeeString.value = senderName.phone_number_localized_fee
    phoneNumberSetupLocalizedFeeString.value = senderName.phone_number_setup_localized_fee
    smsAndCallFeeString.value = senderName.sms_and_call_price_localized_fee
    sender_name_id.value = senderName.id
  }

  const getInitialValue = (formData) => ({ phoneNumberAlias: true, smsAndCall: formData.smsAndCall, phoneNumberAliasZipCode: formData.phoneNumberAliasZipCode })

  const storedDataToFormData = (storedData) => {
    return {
      phoneNumberAlias: true,
      phoneNumberAliasZipCode: '',
      smsAndCall: false,
    }
  }

  const formDataToStoreData = (formData) => {
    const dataToStore = {
      phone_number_alias: formData.phoneNumberAlias,
      phone_number_alias_zip_code: formData.phoneNumberAliasZipCode,
      sms_and_call: formData.smsAndCall,
    }
    for (const key in dataToStore) {
      if (!dataToStore[key]) {
        delete dataToStore[key]
      }
    }

    return dataToStore
  }
  return {
    formData,
    senderNameFormSchema,
    i18n,
    submitButton,
    senderNameError,
    changeHandler,
    storedDataToFormData,
    formDataToStoreData,
    updatePriceData,
    getInitialValue,
    finishPhoneNumberAliasCheckout: (data) => createSenderAlias(formDataToStoreData({
      ...formData.value,
      ...data,
    }), sender_name_id.value).then((response) => {
      return response
    }).catch(({ response }) => {
      openModal(i18n.t('error'), getLocalizedServerError(i18n, 'forms.senderName.errors.', response))
      return null
    }),
  }
}
